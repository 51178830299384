import { useReducer, useEffect } from "react";
import React from "react";
import { RegionRepository } from "../structure/RegionRepository";
import ListeRegion from "./IDEComponents/ListeRegion";
import AfficherRegion from "./IDEComponents/AfficherRegion";
import "./editor.css";
import AucuneRegionChoisie from "./IDEComponents/AucuneRegionChoisie";
import { reducerRegion, TypeActionRegion } from "./ActionRegion";
import { DataLoader } from "../factory/DataLoader";
import VersionManager from "../factory/VersionManager";
import Region from "../structure/Region";
import { ExportedData } from "../structure/ExportedData";

interface IDEProps {
  repository: RegionRepository | null;
}

export function IDE(props: IDEProps) {
  if (props.repository === null) throw new Error("Repository est null");

  const versionManager = new VersionManager();
  const initState = {
    region: null,
    versionManager: versionManager,
    exportedData: null,
    selectedLignes: [],
  };

  const [state, dispatch] = useReducer(reducerRegion, initState);

  let dataLoader = new DataLoader(state.versionManager);

  useEffect(() => {
    if (state.region === null || state.region.loaded) return;

    //Sinon, on doit charger la région
    dataLoader.load(state.region).then((region: Region) => dispatch({ type: TypeActionRegion.choisirRegion, region: region }));
  });

  function afficherBlocGauche(region: Region | null, exportedData: ExportedData | null) {
    if (region === null) return <AucuneRegionChoisie />;

    return <AfficherRegion region={region} dispatcherRegion={dispatch} selectedLignes={state.selectedLignes} exportedData={exportedData} />;
  }

  return (
    <div>
      <ListeRegion repository={props.repository} dispatcherRegion={dispatch} currentRegion={state.region} />
      {afficherBlocGauche(state.region, state.exportedData)}
    </div>
  );
}
