export enum Couleur {
  Rouge = 0,
  Violet = 1,
  Cyan = 2,
  Lime = 3,
  Jaune = 4,
  Orange = 5,
  Marron = 6,
  Vert = 7
}
