import { useState, useEffect } from "react";
import Region from "../../structure/Region";
import React from "react";
import Ligne from "../../structure/Ligne";
import GareDansLigne from "../../structure/GareDansLigne";
import AfficherLignes from "./AfficherRegionComponents/AfficherLignes";
import AfficherGares from "./AfficherRegionComponents/AfficherGares";
import AfficherGaresDansLigne from "./AfficherRegionComponents/AfficherGaresDansLigne";
import { ActionRegion } from "../ActionRegion";
import { ExportedData } from "../../structure/ExportedData";
import ExportButton from "./AfficherRegionComponents/Exportbutton";
import { orderByValue } from "../../Tools";

interface AfficherRegionProps {
  region: Region;
  exportedData: ExportedData | null;
  dispatcherRegion: React.Dispatch<ActionRegion>;
  selectedLignes: Array<Ligne>;
}
interface AfficherRegionState {
  filteredGares: Array<GareDansLigne>;
  selectedLigne: Ligne | null;
}

export default function AfficherRegion(props: AfficherRegionProps) {
  if (props.region === null) throw new Error("La région n'existe pas");

  const initialState = {
    filteredGares: [],
    selectedLigne: null,
  };

  const [precedenteRegion, setPrecedenteRegion] = useState(props.region.id);

  const [state, setState] = useState<AfficherRegionState>(initialState);

  useEffect(() => {
    // On reset le state quand on change de région
    if (precedenteRegion !== props.region.id) {
      setState(initialState);
      setPrecedenteRegion(props.region.id);
    }
  }, [props.region, initialState, precedenteRegion]);

  function afficherGaresSelectionees(ligne: Ligne) {
    return () => {
      setState({
        filteredGares: props.region.garesdansligne.filter((gdl: GareDansLigne) => gdl.ligne.idExterne === ligne.idExterne && !gdl.isDeleted()),
        selectedLigne: ligne,
      });
    };
  }

  return (
    <div id="main" className="afficherRegion">
      <h1>{props.region.nom}</h1>
      <ExportButton data={props.exportedData} dispatcherRegion={props.dispatcherRegion} />
      <AfficherLignes
        lignes={props.region.lignes.sort((a: Ligne, b: Ligne) => orderByValue(a.ordre, b.ordre))}
        onSelectedLigne={afficherGaresSelectionees}
        dispatcherRegion={props.dispatcherRegion}
        selectedLignes={props.selectedLignes}
      />
      {state.selectedLigne !== null && state.filteredGares.length > 0 ? (
        <AfficherGaresDansLigne
          region={props.region}
          gares={state.filteredGares}
          currentLigne={state.selectedLigne}
          dispatcherRegion={props.dispatcherRegion}
          refreshListGares={afficherGaresSelectionees}
        />
      ) : (
        <AfficherGares region={props.region} dispatcherRegion={props.dispatcherRegion} />
      )}
    </div>
  );
}
