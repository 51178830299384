export default class Version {
  numero: number;
  existe: boolean;

  constructor(numero: number, existe: boolean) {
    if (isNaN(numero))
      throw new Error("Le numéro de version doit être un nombre !");

    this.numero = numero;
    this.existe = existe;
  }
}
