import React, { Component } from "react";
import autobind from "autobind-decorator";
import { RegionLoader } from "./factory/RegionLoader";
import { RegionRepository } from "./structure/RegionRepository";

interface LoadRegionButtonProps {
  onRegionCharge: (repository: RegionRepository) => void;
}

export default class LoadRegionButton extends Component<LoadRegionButtonProps> {
  private PathData: string = "data/";
  private _regionLoader: RegionLoader;

  constructor(props: LoadRegionButtonProps) {
    super(props);
    this._regionLoader = new RegionLoader();
  }
  /*
  @autobind
  loadRegion() {
    const zipContent = this._fileReader.result as ArrayBuffer;
    unzip(zipContent, (error, result): void => {
      if (error != null) throw error;

      console.log(result.toString());
    });
  }

  @autobind
  loadFile(files: FileList | null) {
    if (files == null) return;

    let file = files[0];
    this._fileReader.onloadend = this.loadRegion;
    this._fileReader.readAsArrayBuffer(file);
  }*/

  @autobind
  loadRegion(content: string) {
    let regions = this._regionLoader.load(content);
    this.props.onRegionCharge(regions);
  }

  @autobind
  loadFileRegion() {
    const pathRegionCsv = this.PathData + "Regions.csv";
    let xhr = new XMLHttpRequest();
    xhr.open("GET", pathRegionCsv);
    xhr.onload = () => {
      if (xhr.status === 200) this.loadRegion(xhr.responseText);
    };
    xhr.send();
  }

  render() {
    return <button onClick={this.loadFileRegion}>Charger les données</button>;
  }
}
