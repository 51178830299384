import { Component } from "react";
import Ligne from "../../../structure/Ligne";
import React from "react";
import { TypeLigne } from "../../../structure/TypeLigne";
import autobind from "autobind-decorator";

interface LigneItemProps {
  ligne: Ligne;
  selected: boolean;
  afficherGares: () => void;
  onSelectedLigne: (ligne: Ligne, selected: boolean) => void;
}

export default class LigneItem extends Component<LigneItemProps> {
  @autobind
  selectLigne() {
    this.props.onSelectedLigne(this.props.ligne, !this.props.selected);
  }

  render() {
    let version: string;
    if (!this.props.ligne.versionCreation.existe) {
      version = "Nouv.";
    } else {
      if (this.props.ligne.versionSuppression && this.props.ligne.versionSuppression.numero > 0) {
        version = `Suppr. (${this.props.ligne.versionSuppression.numero})`;
      } else {
        version = `+ (${this.props.ligne.versionCreation.numero})`;

        if (this.props.ligne.versionMaj.numero > 0) {
          version += ` (~${this.props.ligne.versionMaj.numero})`;
        }
      }
    }
    return (
      <tr className="ligne" onClick={this.props.afficherGares}>
        <td>
          <input type="checkbox" checked={this.props.selected} onChange={this.selectLigne} />
        </td>
        <td>{this.props.ligne.idExterne}</td>
        <td>{this.props.ligne.nom}</td>
        <td>{TypeLigne[this.props.ligne.type]}</td>
        <td>{this.props.ligne.couleur}</td>
        <td>{this.props.ligne.ordre}</td>
        <td>{version}</td>
      </tr>
    );
  }
}
