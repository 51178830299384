import { Component } from "react";
import React from "react";

export default class AucuneRegionChoisie extends Component {
  render() {
    return (
      <div id="main" className="aucuneRegionChoisie">
        Choissiez une région
      </div>
    );
  }
}
