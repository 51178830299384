import React from "react";
import "leaflet/dist/leaflet.css";
import "./leaflet-container.css";

export interface CarteDesGaresCommunProps {}

export abstract class CarteDesGaresCommun<
  T extends CarteDesGaresCommunProps
> extends React.Component<T> {
  componentDidMount() {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  }
}
