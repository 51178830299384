import { Component } from "react";
import React from "react";
import Gare from "../../../structure/Gare";
import { ActionRegion } from "../../ActionRegion";
import Region from "../../../structure/Region";
import "./gareItemCommun.css";

export interface GareItemCommunProps {
  onSelectedGare: (gare: Gare, selected: boolean) => void;
  selected: boolean;
  region: Region;
  dispatcherRegion: React.Dispatch<ActionRegion>;
}

export abstract class GareItemCommun<
  T extends GareItemCommunProps
> extends Component<T> {}
