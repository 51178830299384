import { TypeLigne } from "../structure/TypeLigne";
import Ligne from "../structure/Ligne";
import { IVersionManager } from "./VersionManager";

export class LigneFactory {
  private _versionManager: IVersionManager;
  public constructor(versionManager: IVersionManager) {
    this._versionManager = versionManager;
  }

  public generate(
    idExterne: string,
    nom: string,
    type: TypeLigne,
    couleur: string,
    ordre: number
  ): Ligne {
    let ligne = new Ligne(
      idExterne,
      nom,
      type,
      ordre,
      couleur,
      this._versionManager.getWorkspaceVersion(),
      this._versionManager.getNoneVersion(),
      this._versionManager.getNoneVersion()
    );
    return ligne;
  }
}
