import Version from "../structure/Version";

export interface IVersionManager {
  versions: { [id: number]: Version };

  getVersion: (id: number) => Version;
  getWorkspaceVersion: () => Version;
  getNoneVersion: () => Version;
  getNewNumeroVersion: () => number;
}

export default class VersionManager implements IVersionManager {
  public versions: { [id: number]: Version };
  private _workspaceVersion: Version;
  private _noneVersion: Version;

  public constructor() {
    this.versions = {};
    this._workspaceVersion = new Version(-1, false);
    this._noneVersion = new Version(0, true);
  }

  public getVersion(id: number): Version {
    if (isNaN(id))
      throw new Error("Le numéro de version doit être un nombre !");

    if (this.versions[id] === undefined) {
      this.versions[id] = new Version(id, true);
    }

    return this.versions[id];
  }

  public getWorkspaceVersion(): Version {
    return this._workspaceVersion;
  }

  public getNoneVersion(): Version {
    return this._noneVersion;
  }

  public getNewNumeroVersion(): number {
    const maxVersion = Math.max(
      ...Object.keys(this.versions).map(item => parseInt(item))
    );

    if (maxVersion < 0) return 1;

    return maxVersion + 1;
  }
}
