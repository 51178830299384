import { Component } from "react";
import React from "react";
import Gare from "../../../structure/Gare";
import autobind from "autobind-decorator";
import Region from "../../../structure/Region";
import Ligne from "../../../structure/Ligne";
import { ActionRegion, TypeActionRegion } from "../../ActionRegion";
import "./afficherGaresCommun.css";

export interface AfficherGaresCommunProps {
  region: Region;
  dispatcherRegion: React.Dispatch<ActionRegion>;
}

export interface AfficherGaresCommunState {
  selectedGares: Array<Gare>;
  selectedLigneId: string;
  selectedLigne: Ligne | null;
}

export abstract class AfficherGaresCommun<
  T extends AfficherGaresCommunProps
> extends Component<T, AfficherGaresCommunState> {
  public constructor(props: T, firstLine: Ligne | undefined) {
    super(props);
    console.log(this.props.region);
    const defaultState = { selectedGares: [] };
    if (this.props.region.loaded && firstLine !== undefined)
      this.state = {
        ...defaultState,
        selectedLigneId: firstLine.idExterne,
        selectedLigne: firstLine
      };
    else
      this.state = {
        ...defaultState,
        selectedLigneId: "",
        selectedLigne: null
      };
  }

  @autobind
  onSelectedGare(gare: Gare, selected: boolean) {
    let newSelectedGares: Array<Gare>;
    if (selected) newSelectedGares = [...this.state.selectedGares, gare];
    else {
      newSelectedGares = this.state.selectedGares;
      let posGare = newSelectedGares.indexOf(gare);
      if (posGare > -1) {
        newSelectedGares.splice(posGare, 1);
      }
    }
    this.setState({ selectedGares: newSelectedGares });
  }

  @autobind
  addGareToLine(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    const { selectedGares, selectedLigne } = this.state;

    if (selectedLigne === null) return;

    for (let gare of selectedGares) {
      //      region.garesdansligne.find((gdl: GareDansLigne) => gdl.gare.idExterne === gare.idExterne && gdl.ligne.idExterne === selectedLigne.idExterne);
      this.props.dispatcherRegion({
        type: TypeActionRegion.creerEtAjouterGareDansLigne,
        gare,
        ligne: selectedLigne
      });
    }
  }

  @autobind
  updateSelectedLigne(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedLigneId = event.target.value;
    console.log("Choix de la ligne " + selectedLigneId + " dans le select");
    console.log(this.props.region.lignes);
    let selected = this.props.region.lignes.find(
      (ligne: Ligne) =>
        selectedLigneId !== "" && ligne.idExterne === selectedLigneId
    );
    if (selected !== undefined) {
      this.setState({
        selectedLigne: selected,
        selectedLigneId: selectedLigneId
      });
    }
  }

  @autobind
  toggleAllCheckbox(listeGares: Array<Gare>) {
    if (this.state.selectedGares.length !== listeGares.length)
      //On coche tout
      this.setState({
        selectedGares: listeGares
      });
    else this.setState({ selectedGares: [] });
  }

  @autobind
  deleteGare() {
    if (this.state.selectedGares.length > 0)
      this.state.selectedGares.forEach((gare: Gare) =>
        this.props.dispatcherRegion({
          type: TypeActionRegion.supprimerGare,
          gare
        })
      );
  }

  protected afficherBoutonCommun(lignes: Array<Ligne>) {
    return (
      <div className="button-group">
        <select
          value={this.state.selectedLigneId}
          onChange={this.updateSelectedLigne}
        >
          {lignes.map((ligne: Ligne) => (
            <option key={ligne.idExterne} value={ligne.idExterne}>
              {ligne.nom}
            </option>
          ))}
        </select>
        <button onClick={this.addGareToLine}>Ajouter à une ligne</button> |
        <button onClick={this.deleteGare}>Supprimer gare</button>
      </div>
    );
  }
}
