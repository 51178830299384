import React from "react";
import autobind from "autobind-decorator";
import GareDansLigne from "../../../structure/GareDansLigne";
import AfficherPoint from "./AfficherPoint";
import { TypeActionRegion } from "../../ActionRegion";
import { GareItemCommunProps, GareItemCommun } from "./GareItemCommun";

interface GareDansLigneItemProps extends GareItemCommunProps {
  gdl: GareDansLigne;
}

export default class GareDansLigneItem extends GareItemCommun<GareDansLigneItemProps> {
  @autobind
  selectGare() {
    this.props.onSelectedGare(this.props.gdl.gare, !this.props.selected);
  }

  @autobind
  changeOrder(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();

    let newGdl: GareDansLigne = this.props.gdl.withOrdre(parseInt(event.target.value));

    this.props.dispatcherRegion({
      type: TypeActionRegion.modifierGareDansLigne,
      gareDansLigne: newGdl,
    });
  }

  render() {
    return (
      <tr>
        <th>
          <input type="checkbox" checked={this.props.selected} onChange={this.selectGare} />
        </th>
        <td>{this.props.gdl.gare.nom}</td>
        <td>
          <input className="order" type="number" value={this.props.gdl.ordre} onChange={this.changeOrder} />
        </td>
        <td className="schema-cellule">
          <AfficherPoint fond={this.props.gdl.pdlFond} point={this.props.gdl.pdlPoint} couleur={this.props.gdl.ligne.couleur} />
        </td>
      </tr>
    );
  }
}
