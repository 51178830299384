import React from "react";
import autobind from "autobind-decorator";
import Gare from "../../../structure/Gare";
import { GareItemCommunProps, GareItemCommun } from "./GareItemCommun";

interface GareItemProps extends GareItemCommunProps {
  gare: Gare;
}

export default class GareItem extends GareItemCommun<GareItemProps> {
  @autobind
  selectGare() {
    this.props.onSelectedGare(this.props.gare, !this.props.selected);
  }

  render() {
    return (
      <tr>
        <th>
          <input
            type="checkbox"
            checked={this.props.selected}
            onChange={this.selectGare}
          />
        </th>
        <td>{this.props.gare.nom}</td>
      </tr>
    );
  }
}
