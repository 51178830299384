import { TypeLigne } from "./TypeLigne";
import Version from "./Version";
import { VersionnedItem } from "./VersionnedItem";

export default class Ligne extends VersionnedItem {
  idExterne: string;
  nom: string;
  type: TypeLigne;
  ordre: number;
  couleur: string;

  constructor(
    idExterne: string,
    nom: string,
    type: TypeLigne,
    ordre: number,
    couleur: string,
    vCreation: Version,
    vMaj: Version,
    vSuppression: Version
  ) {
    super(vCreation, vMaj, vSuppression);
    this.idExterne = idExterne;
    this.nom = nom;
    this.type = type;
    this.ordre = ordre;
    this.couleur = couleur;
  }
}
