import React from "react";
import Gare from "../../../structure/Gare";
import autobind from "autobind-decorator";
import { orderByValue } from "../../../Tools";
import { CarteDesGares } from "./CarteDesGares";
import GareItem from "./GareItem";
import {
  AfficherGaresCommunProps,
  AfficherGaresCommun
} from "./AfficherGaresCommun";

interface AfficherGaresProps extends AfficherGaresCommunProps {}

export default class AfficherGares extends AfficherGaresCommun<
  AfficherGaresProps
> {
  public constructor(props: AfficherGaresProps) {
    super(props, props.region.lignes[0]);
  }

  @autobind
  toggleAllCheckbox() {
    super.toggleAllCheckbox(this.props.region.gares);
  }

  render() {
    return (
      <div>
        {this.props.region.loaded && (
          <CarteDesGares gares={this.props.region.gares} />
        )}
        <h2>Gares de la region {this.props.region.nom}</h2>
        <div>{this.afficherBoutonCommun(this.props.region.lignes)}</div>
        <table>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={this.toggleAllCheckbox} />
              </th>
              <th>Nom</th>
            </tr>
          </thead>
          <tbody>
            {this.props.region.loaded &&
              this.props.region.gares
                .filter((gare: Gare) => !gare.isDeleted())
                .sort((a: Gare, b: Gare) => orderByValue(a.nom, b.nom))
                .map((gare: Gare) => (
                  <GareItem
                    key={gare.idExterne}
                    gare={gare}
                    onSelectedGare={this.onSelectedGare}
                    selected={this.state.selectedGares.indexOf(gare) !== -1}
                    region={this.props.region}
                    dispatcherRegion={this.props.dispatcherRegion}
                  />
                ))}
          </tbody>
        </table>
      </div>
    );
  }
}
