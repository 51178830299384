import Version from "./Version";

export abstract class VersionnedItem {
  versionCreation: Version;
  versionMaj: Version;
  versionSuppression: Version | null;

  constructor(vCreation: Version, vMaj: Version, vSuppression: Version | null) {
    this.versionCreation = vCreation;
    this.versionMaj = vMaj;
    this.versionSuppression = vSuppression;
  }

  public isDeleted(): boolean {
    return (
      this.versionSuppression !== null && this.versionSuppression.numero !== 0
    );
  }
}
