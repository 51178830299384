import { Component } from "react";
import logo from "./logo.svg";
import React from "react";
import LoadRegionButton from "./LoadRegionButton";
import { RegionRepository } from "./structure/RegionRepository";
import "./Loader.css";

interface Props {
  onRegionCharge: (repository: RegionRepository) => void;
}

export default class Loader extends Component<Props> {
  render() {
    return (
      <div>
        <div id="menu">
          <div className="pure-menu">
            <span className="pure-menu-heading">PasseGares</span>
          </div>
        </div>

        <div id="main" className="loader">
          <img src={logo} className="loader-logo" alt="logo" />
          <p>PasseGares Editor</p>
          <LoadRegionButton onRegionCharge={this.props.onRegionCharge} />
        </div>
      </div>
    );
  }
}
