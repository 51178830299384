import Gare from "./Gare";
import Ligne from "./Ligne";
import { Fond, Point } from "./PointDansLigne";
import Version from "./Version";
import { VersionnedItem } from "./VersionnedItem";

export default class GareDansLigne extends VersionnedItem {
  gare: Gare;
  ligne: Ligne;
  ordre: number;
  pdlFond: Fond;
  pdlPoint: Point;

  constructor(
    gare: Gare,
    ligne: Ligne,
    ordre: number,
    pdlFond: number,
    pdlPoint: number,
    vCreation: Version,
    vMaj: Version,
    vSuppression: Version | null //Ne doit pas être nul
  ) {
    super(vCreation, vMaj, vSuppression);
    this.gare = gare;
    this.ligne = ligne;
    this.ordre = ordre;
    this.pdlFond = pdlFond;
    this.pdlPoint = pdlPoint;
  }

  public withOrdre(ordre: number): GareDansLigne {
    return new GareDansLigne(
      this.gare,
      this.ligne,
      ordre,
      this.pdlFond,
      this.pdlPoint,
      this.versionCreation,
      this.versionMaj,
      this.versionSuppression
    );
  }
}
