import React from "react";
import { CircleMarker, Map, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./leaflet-container.css";
import Gare from "../../../structure/Gare";
import {
  CarteDesGaresCommunProps,
  CarteDesGaresCommun
} from "./CarteDesGaresCommun";

interface CarteDesGaresProps extends CarteDesGaresCommunProps {
  gares: Array<Gare>;
}

export class CarteDesGares extends CarteDesGaresCommun<CarteDesGaresProps> {
  render() {
    const couleursExploitants: Array<{ exploitant: string, couleur: string }> = [];
    const couleurs = ["darkcyan", "darkmagenta", "darkgoldenrod", "darksalmon", "darkseagreen", "darkslategray"];
    return (
      <div className="leaflet-container">
        <Map
          center={
            this.props.gares
              .filter((gare: Gare) => !gare.isDeleted())
              .map((gare: Gare): [number, number] => [
                gare.latitude,
                gare.longitude
              ])
              .reduce((acc, item) => [acc[0] + item[0], acc[1] + item[1]])
              .map((value: number) => value / this.props.gares.length) as [
              number,
              number
            ]
          }
          zoom={12}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {this.props.gares
            .filter((gare: Gare) => !gare.isDeleted())
            .map((gare: Gare) => {
              const position: [number, number] = [gare.latitude, gare.longitude];

              let couleurMarker = couleursExploitants.find(item => item.exploitant === gare.exploitant);
              if (!couleurMarker) {
                couleurMarker = { exploitant: gare.exploitant, couleur: couleurs[couleursExploitants.length % couleurs.length] }
                couleursExploitants.push(couleurMarker);
              }
              return (
                <CircleMarker key={gare.idExterne} radius={4} center={position} color={couleurMarker.couleur} fill={true} fillColor={"white"} fillOpacity={1} weight={2}>
                  <Popup>
                    <b>Nom</b>
                    <br />
                    {gare.nom}
                    <br />
                    <b>Id</b>
                    <br />
                    {gare.id}
                    <br />
                    <b>Exploitant</b>
                    <br />
                    {gare.exploitant}
                    <br />
                  </Popup>
                </CircleMarker>
              );
            })}
        </Map>
      </div>
    );
  }
}
