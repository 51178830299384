import Region from "../../../structure/Region";
import { Component } from "react";
import React from "react";
import autobind from "autobind-decorator";
import { ActionRegion, TypeActionRegion } from "../../ActionRegion";
import "./listeRegionItem.css";

interface ListeRegionItemProps {
  region: Region;
  dispatcherRegion: React.Dispatch<ActionRegion>;
  isCurrentRegion: boolean;
}

export default class ListeRegionItem extends Component<ListeRegionItemProps> {
  @autobind
  choisirRegion() {
    this.props.dispatcherRegion({
      type: TypeActionRegion.choisirRegion,
      region: this.props.region
    });
  }

  render() {
    return (
      <li
        className={
          "pure-menu-item region-item" +
          (this.props.isCurrentRegion ? " pure-menu-selected" : "")
        }
        onClick={this.choisirRegion}
      >
        {this.props.region.nom}
      </li>
    );
  }
}
