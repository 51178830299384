import { Component } from "react";
import { RegionRepository } from "../../structure/RegionRepository";
import Region from "../../structure/Region";
import React from "react";
import ListeRegionItem from "./ListeRegionComponents/ListeRegionItem";
import { ActionRegion } from "../ActionRegion";

interface ListeRegionProps {
  repository: RegionRepository;
  dispatcherRegion: React.Dispatch<ActionRegion>;
  currentRegion: Region | null;
}

export default class ListeRegion extends Component<ListeRegionProps> {
  render() {
    return (
      <div id="menu">
        <div className="pure-menu">
          <span className="pure-menu-heading">PasseGares</span>

          <ul className="pure-menu-list">
            {this.props.repository.regions.map((value: Region) => (
              <ListeRegionItem
                key={value.id}
                region={value}
                dispatcherRegion={this.props.dispatcherRegion}
                isCurrentRegion={
                  this.props.currentRegion !== null &&
                  this.props.currentRegion.id === value.id
                }
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
