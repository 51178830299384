import React, { Component } from "react";
import Loader from "./Loader";
import autobind from "autobind-decorator";
import { RegionRepository } from "./structure/RegionRepository";
import { IDE } from "./IDE/IDE";
import "./App.css";
import "purecss/build/pure.css";

interface State {
  loaded: boolean;
  repository: RegionRepository | null;
}

export default class App extends Component<any, State> {
  public constructor(props: any) {
    super(props);
    this.state = { loaded: false, repository: null };
  }

  @autobind
  chargerStructure(repository: RegionRepository) {
    this.setState({
      loaded: true,
      repository: repository
    });
  }

  render() {
    return (
      <div className="app">
        {this.state.loaded ? (
          <IDE repository={this.state.repository} />
        ) : (
          <Loader onRegionCharge={this.chargerStructure} />
        )}
      </div>
    );
  }
}
