import { Component } from "react";
import React from "react";
import { TypeLigne } from "../../../structure/TypeLigne";
import autobind from "autobind-decorator";
import { ActionRegion, TypeActionRegion } from "../../ActionRegion";

interface AjouterLigneFormProps {
  dispatcherRegion: React.Dispatch<ActionRegion>;
}

interface AjouterLigneFormState {
  idExterne: string;
  nom: string;
  type: TypeLigne;
  couleur: string;
  ordre: number;
}

export default class AjouterLigneForm extends Component<
  AjouterLigneFormProps,
  AjouterLigneFormState
> {
  private defaultState = {
    idExterne: "",
    nom: "",
    type: TypeLigne.Metro,
    couleur: "#000000",
    ordre: 0
  };

  state: AjouterLigneFormState = this.defaultState;

  @autobind
  updateIdExterne(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target === null) return;

    let idExterne = event.target.value;
    this.setState({ idExterne });
  }

  @autobind
  updateName(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target === null) return;

    let nom = event.target.value;
    this.setState({ nom });
  }

  @autobind
  updateType(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target === null) return;

    let type = event.target.value;
    this.setState({
      type: ((type as keyof typeof TypeLigne) as unknown) as TypeLigne
    });
  }

  @autobind
  updateColor(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target === null) return;

    let couleur = event.target.value;
    this.setState({ couleur });
  }

  @autobind
  updateOrder(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target === null) return;

    let ordre = parseInt(event.target.value);
    if (isNaN(ordre)) ordre = 0;
    this.setState({ ordre });
  }

  @autobind
  ajouterLigne(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const { idExterne, nom, type, couleur, ordre } = this.state;
    this.props.dispatcherRegion({
      type: TypeActionRegion.creerEtAjouterLigne,
      idExterne,
      nom,
      typeLigne: type,
      couleur,
      ordre
    });
    this.setState(this.defaultState);
  }

  render() {
    return (
      <form onSubmit={this.ajouterLigne}>
        <table>
          <tbody>
            <tr>
              <td>
                <input
                  value={this.state.idExterne}
                  onChange={this.updateIdExterne}
                  placeholder="Id"
                  required
                />
              </td>
              <td>
                <input
                  value={this.state.nom}
                  onChange={this.updateName}
                  placeholder="Nom"
                  required
                />
              </td>
              <td>
                <select value={this.state.type} onChange={this.updateType}>
                  {Object.keys(TypeLigne)
                    .filter((elt: string) => isNaN(parseInt(elt)))
                    .map((value: string, key: number) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                </select>
              </td>
              <td>
                <input
                  value={this.state.couleur}
                  onChange={this.updateColor}
                  placeholder="Couleur"
                />
              </td>
              <td>
                <input
                  value={this.state.ordre}
                  onChange={this.updateOrder}
                  placeholder="Ordre"
                  type="number"
                />
              </td>
              <td>
                <input type="submit" value="Ajouter" />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  }
}
